<style lang="scss" scoped>
$height: 100px;
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $height;
  height: $height;
  line-height: $height;
  text-align: center;
}
.avatar {
  width: $height;
  height: $height;
  display: block;
}
</style>
<template>
  <el-form
    size="small"
    :inline="true"
    :model="valueRef.ruleForm"
    ref="ruleForm"
    :rules="state.rules"
    label-width="140px"
  >
    <el-row>
      <el-col :span="8">
        <el-form-item label="商品名稱" prop="goods_name">
          <el-input
            v-model="valueRef.ruleForm.goods_name"
            placeholder="請輸入商品名稱"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="所屬分類" prop="classify">
          <el-cascader
            v-model="valueRef.ruleForm.classify"
            :options="valueRef.ruleForm.options"
            :props="{ expandTrigger: 'hover' }"
            placeholder="選擇分類："
            class="flex1"
          ></el-cascader>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="搜索關鍵字" prop="keysword">
          <el-input
            v-model="valueRef.ruleForm.keysword"
            placeholder="請輸入搜索關鍵字"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="狀態" prop="status">
          <el-select placeholder="請選擇" v-model="valueRef.ruleForm.status">
            <el-option label="正常" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="是否熱門" prop="is_hot">
          <el-radio-group v-model="valueRef.ruleForm.is_hot">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="8">
        <el-form-item label="積分兌換" prop="is_point_exchange">
          <el-radio-group v-model="valueRef.ruleForm.is_point_exchange">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col> -->
      <el-col :span="8" v-if="is_spec_show">
        <el-form-item label="有無規格" prop="is_point">
          <el-radio-group v-model="valueRef.ruleForm.is_point">
            <el-radio :label="1">有</el-radio>
            <el-radio :label="0">無</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="8" v-if="valueRef.ruleForm.is_point_exchange">
        <el-form-item label="兌換所需積分" prop="exchange_integral">
          <el-input-number
            v-model="valueRef.ruleForm.exchange_integral"
          ></el-input-number>
        </el-form-item>
      </el-col> -->
      <el-col :span="8">
        <el-form-item label="商品成本" prop="cost">
          <el-input-number
            v-model="valueRef.ruleForm.cost"
            :min="0"
            placeholder="請輸入商品成本"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="商品價格" prop="price">
          <el-input-number
            :min="0"
            v-model="valueRef.ruleForm.price"
            placeholder="請輸入商品價格"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="商品庫存" prop="inventory">
          <el-input-number
            :min="0"
            v-model="valueRef.ruleForm.inventory"
            placeholder="請輸入商品庫存"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="反饋積分" prop="feedback_integral">
          <el-input-number
            :min="0"
            v-model="valueRef.ruleForm.feedback_integral"
            placeholder="請輸入反饋積分"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="商品簡介" prop="describe">
          <el-input
            type="textarea"
            v-model="valueRef.ruleForm.describe"
            placeholder="請輸入商品簡介"
            :min="0"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="供應商資料" prop="datum">
          <el-input
            type="textarea"
            v-model="valueRef.ruleForm.datum"
            placeholder="請輸入供應商資料"
            :min="0"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="商品封面" prop="goods_img">
          <el-upload
            class="avatar-uploader"
            action="https://wanchuensiu.bscd8.com/upload/uploadFiles"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            drag
            :on-progress="onProgress"
            :on-error="onError"
          >
            <img
              v-if="valueRef.ruleForm.goods_img"
              :src="valueRef.ruleForm.goods_img"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { reactive, getCurrentInstance, ref, defineComponent, watch } from "vue";

export default defineComponent({
  props: {
    popData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    is_spec_show: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message, $loading, $http } = proxy;
    let loading = null;
    const valueRef = ref({
      ruleForm: {
        goods_name: "", // 商品名稱
        price: 0, // 商品價格
        cost: 0, //成本
        describe: "", //商品简介
        status: "1", // 狀態
        goods_type: "", //商品类别
        keysword: "", // 搜索關鍵詞
        is_point_exchange: 0, //可否兑换积分
        feedback_integral: 0, //反饋積分
        exchange_integral: 0, //兑换所需积分
        is_hot: 1, // 是否熱銷
        goods_introduce: "", //商品介绍
        goods_img: "", // 商品封面圖
        is_point: 1, // 有無規格
        options: [
          // 多級分類數據
        ],
        classify: [], // 多級選擇的值
        inventory: 1, // 商品庫存
        datum: "", // 供应商资料
      },
    });

    if (Object.keys(props.popData).length) {
      init(props.popData);
    }

    watch(
      () => props.popData,
      (val, old) => {
        if (!Object.keys(val).length) return;
        init(val);
      },
      { deep: true }
    );

    function init(obj) {
      valueRef.value.ruleForm = {
        goods_name: obj.goods_name, // 商品名稱
        price: parseInt(obj.price), // 商品價格
        cost: parseInt(obj.cost), //成本
        describe: obj.describe, //商品简介
        status: "" + obj.status, // 狀態
        goods_type: "", //商品类别
        keysword: obj.keysword, // 搜索關鍵詞
        is_point_exchange: 0, //可否兑换积分
        feedback_integral: obj.feedback_integral, //反饋積分
        exchange_integral: 0, //兑换所需积分
        is_hot: obj.is_hot, // 是否熱銷
        goods_introduce: obj.describe, //商品介绍
        goods_img: obj.goods_img, // 商品封面圖
        is_point: obj.has_specs, // 有無規格
        options: valueRef.value.ruleForm.options, // 多級分類數據
        classify: obj.parent ? [obj.parent, obj.sort_id] : [obj.sort_id], // 多級選擇的值
        inventory: obj.goods_stock, // 商品庫存
        datum: obj.supplier, // 供应商资料
      };
    }

    const state = reactive({
      rules: {
        //表单验证
        goods_name: [
          { required: true, message: "請輸入商品名稱", trigger: "blur" },
        ],
        price: [{ required: true, message: "請輸入商品價格", trigger: "blur" }],
        cost: [{ required: true, message: "請輸入商品成本", trigger: "blur" }],
        feedback_integral: [
          { required: true, message: "請輸入反饋積分", trigger: "blur" },
        ],
        keysword: [
          { required: true, message: "請輸入搜索關鍵詞", trigger: "blur" },
        ],
        is_point_exchange: [
          { required: true, message: "可否積分兌換", trigger: "blur" },
        ],
        is_point: [{ required: true, message: "有無規格", trigger: "blur" }],
        is_hot: [{ required: true, message: "是否熱門推薦", trigger: "blur" }],
        describe: [
          // { required: true, message: "請輸入商品簡介", trigger: "blur" },
        ],
        datum: [
          // { required: true, message: "請輸入供應商資料", trigger: "blur" },
        ],
        exchange_integral: [
          { required: true, message: "兌換所需積分", trigger: "blur" },
        ],
        goods_img: [
          { required: true, message: "請上傳商品封面圖", trigger: "submit" },
        ],
        classify: [
          { required: true, message: "請選擇商品分類", trigger: "change" },
        ],
        status: [
          { required: true, message: "請選擇商品狀態", trigger: "change" },
        ],
        inventory: [
          { required: true, message: "請輸入商品庫存", trigger: "blur" },
        ],
      },
    });

    /*
    文件上传成功时的钩子
    */
    const handleAvatarSuccess = (res, file) => {
      valueRef.value.ruleForm.goods_img = file.response.data.file_path;
      $message.success("上傳成功");
      loading.close();
    };
    /*
    上传文件之前的钩子，参数为上传的文件，
    若返回 false 或者返回 Promise 且被
    reject，则停止上传
    */
    const beforeAvatarUpload = (file) => {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        $message.error("圖片大小不能超過 2M ！");
      }
      return isLt2M;
    };

    const onProgress = (event, file, fileList) => {
      loading = $loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    const onError = (err) => {
      $message.error(err);
      loading.close();
    };

    const focus = async () => {
      // 获取分类参数
      valueRef.value.ruleForm.options = [];
      const { data } = await $http.getAllGoodsSort();
      Object.values(data).forEach((item) => {
        const obj = {
          value: item.id,
          label: item.sort_name,
        };
        if (Object.values(item.children).length) {
          obj.children = [];
          Object.values(item.children).forEach((curr) => {
            obj.children.push({
              value: curr.id,
              label: curr.sort_name,
            });
          });
        }
        valueRef.value.ruleForm.options.push(obj);
      });
    };
    focus();

    const reset = () => {
      valueRef.value.ruleForm = {
        ruleForm: {
          goods_name: "", // 商品名稱
          price: 0, // 商品價格
          cost: 0, //成本
          describe: "", //商品简介
          status: "1", // 狀態
          goods_type: "", //商品类别
          keysword: "", // 搜索關鍵詞
          is_point_exchange: 0, //可否兑换积分
          feedback_integral: 0, //反饋積分
          exchange_integral: 0, //兑换所需积分
          is_hot: 1, // 是否熱銷
          goods_introduce: "", //商品介绍
          goods_img: "", // 商品封面圖
          is_point: 1, // 有無規格
          options: [
            // 多級分類數據
          ],
          classify: [], // 多級選擇的值
          inventory: 1, // 商品庫存
          datum: "", // 供应商资料
        },
      };
      focus();
    };

    return {
      state,
      handleAvatarSuccess,
      beforeAvatarUpload,
      valueRef,
      onProgress,
      onError,
      reset,
      props,
    };
  },
});
</script>
