<style lang="scss" scoped>
</style>
<template>
  <div class="">
    <el-upload
      action="https://wanchuensiu.bscd8.com/upload/uploadFiles"
      :file-list="state.fileList"
      list-type="picture"
      :limit="6"
      multiple
      :on-exceed="onExceed"
      :on-change="onChange"
      :on-remove="onRemove"
      :on-success="onSuccess"
    >
      <el-button size="small" type="primary">點擊上傳</el-button>
      <template #tip>
        <div class="el-upload__tip">只能上傳 jpg/png 圖片，且不超過 500kb</div>
      </template>
    </el-upload>
  </div>
</template>

<script>
import { defineComponent, reactive, getCurrentInstance, watch } from "vue";

export default defineComponent({
  props: {
    fileList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup(props) {
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message } = proxy;
    const state = reactive({
      fileList: props.fileList.length ? props.fileList : [],
      imgList: props.fileList, // 商品圖片列表
    });

    watch(
      () => props.fileList,
      (val, old) => {
        state.imgList = [];
        if (val.length) {
          state.fileList = val;
          val.forEach((item) => {
            state.imgList.push(item.img_url);
          });
        }
      },
      { deep: true }
    );

    const onExceed = () => {
      $message.error("超出圖片最大上傳個數");
    };

    const onChange = (file, fileList) => {};

    const onSuccess = (fileList) => {
      state.imgList.push(fileList.data.file_path);
      state.fileList.map((item, index) => {
        const obj = {
          name: "img" + (index + 1) + ".png",
          img_url: item.img_url,
        };
        item = obj
        // state.fileList.push(obj);
      });
      console.log(state.fileList);
    };

    const onRemove = (file, fileList) => {
      console.log(file);
      state.imgList = state.imgList.filter((item) => {
        if (file.img_url) {
          return item != file.img_url;
        } else {
          return item != file.response.data.file_path;
        }
      });
      console.log(file.img_url ? file.img_url : file.response.data.file_path);
    };

    const reset = () => {
      state.fileList = [];
      state.imgList = [];
    };

    return { state, onExceed, onChange, onRemove, reset, onSuccess };
  },
});
</script>
